import React from "react";
import PropTypes from "prop-types";

import { withStyles } from "@material-ui/core/styles";
import classNames from "classnames";

import Card from "@material-ui/core/Card";
import CardMedia from "@material-ui/core/CardMedia";
import CardContent from "@material-ui/core/CardContent";
import CardActions from "@material-ui/core/CardActions";
import CardHeader from "@material-ui/core/CardHeader";

import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import Button from "@material-ui/core/Button";
import Chip from "@material-ui/core/Chip";
import Avatar from "@material-ui/core/Avatar";

import FormControl from "@material-ui/core/FormControl";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import CircularProgress from "@material-ui/core/CircularProgress";

import FavoriteIcon from "@material-ui/icons/Favorite";
import ShareIcon from "@material-ui/icons/Share";
import DoubleCheckIcon from "@material-ui/icons/DoneAll";
import ShippingIcon from "@material-ui/icons/LocalShipping";
import CheckIcon from "@material-ui/icons/Check";

import ResultItemPriceRow from "./../common/ResultItemPriceRow";
import brandLogoUrls from "./../common/logoUrls";

import commons from "./../common/commons";
import ProductDataSheet from "./ProductSheet";

const hebrewLables = {
  freeShipping: "משלוח חינם",
  lastPurchase: "רכישה אחרונה: {x}",
  setOfTires: "סט צמיגים",
  bestOfferAlt:
    "הציעו מחיר שיותר מתאים לכם, אנחנו נבדוק אותו ונחזור אליכם עם תשובה. הצעות שיתקבלו תקפות ל-24 שעות בלבד",
  bestOfferSubmitted: "הצעת המחיר שלך נשלחה ותענה בהקדם!",
  likeButtonTextToggleOff: "לחץ לסמן שאתה אוהב את המוצר",
  bestOfferCTA: "שלח הצעה",
  bestOffer: "הצע מחיר אחר",
  bestOfferAmount: "כמה תרצו לשלם",
  bestOfferPhone: "מספר נייד",
  paidShipping: "משלוח: ₪60",
  outStock: "אזל המלאי / צפי: %s",
  learnMoreButtonText: "מידע נוסף",
  addToCartButtonText: "הוסף לסל",
};

const inputProps = {
  pattern: "[0-9]*",
  type: "number",
  step: "0.01",
};

const styles = (theme) => ({
  card: {
    display: "block",
    maxWidth: 365,
    marginTop: 40,
    marginLeft: "auto",
    marginRight: "auto",
    position: "relative",
  },
  youtube: {
    maxWidth: 400,
    width: "100%",
    height: 245,
  },
  content: {
    padding: 5,
    display: "flex",
    justifyContent: "center",
    flexWrap: "wrap",
  },
  chipBlackFriday: {
    flip: false,
    direction: "ltr",
    fontWeight: 800,
    width: 138,
    margin: "4px auto",
    color: "#fafafa",
    background: "#063970",
  },
  media: {
    height: 194,
    backgroundPosition: "top",
  },
  brandLogo: {
    position: "absolute",
    maxHeight: 26,
    top: 3,
    right: 3,
  },
  actions: {
    display: "flex",
  },
  bestOfferSpinner: {
    color: "#848383",
  },
  boText: {
    fontSize: 14,
  },
  greenCheck: {
    color: "#0c9b4b",
  },
  likesCounter: Object.assign({}, theme.typography.button, {
    margin: theme.spacing(),
  }),
  button: {
    margin: theme.spacing(),
    width: 319,
  },
  description: {
    padding: theme.spacing(2),
  },
  cardHeader: {
    flip: false,
    direction: "ltr",
  },
  cardMediaContainer: {
    position: "relative",
  },
  chip: {
    flip: false,
    direction: "ltr",
    position: "absolute",
    top: "5px",
    right: "5px",
  },
  featureChip: {
    margin: theme.spacing() / 2,
  },
  outStock: {
    background: "#ba2218",
    color: "#fefefe",
  },
  liked: {
    color: "#ef7600",
  },
  freeShip: {
    color: "#313330",
    background: "#f4b342",
  },
  freeShipIcon: {
    color: "#313330",
    background: "#e5a73b",
  },
  chipsContainer: {
    marginTop: theme.spacing(),
    display: "flex",
    justifyContent: "center",
    flexWrap: "wrap",
  },
  paidShippingChip: {
    background: "#e5a639",
    marginTop: 4,
  },
  paidShippingAvatar: {
    background: "#e5a639",
    color: "#313330",
  },
  paidShippingIcon: {
    background: "#f4b342",
    color: "#313330",
  },
  "@media (min-width: 1024px)": {
    card: {
      display: "none",
    },
  },
});

class ProductCard extends React.Component {
  state = {
    expanded: false,
    bestOfferExpanded: false,
    phone: "",
    price: "",
    transit: false,
  };

  handleExpandClick = () => {
    this.setState({ expanded: !this.state.expanded });
  };

  reportLike = () => {
    /* eslint no-underscore-dangle: 0 */
    this.props.reportLike(this.props.data._id);
  };

  toggleBestOffer = () => {
    this.setState({ bestOfferExpanded: !this.state.bestOfferExpanded });
  };

  handleChangePrice = () => {
    this.setState({ ...this.state, price: event.target.value });
  };

  handleChangePhone = () => {
    this.setState({ ...this.state, phone: event.target.value });
  };

  submitBestOffer = () => {
    const { phone, price } = this.state;
    const { displayName, brand, displaySize, ean } = this.props.data;

    if (typeof fbq === "function") {
      fbq("track", "AddToCart", {
        content_ids: [ean],
        content_type: "product",
        value: price,
        currency: "ILS",
      });
    }

    this.setState({ transit: true });

    this.props.submitBestOffer({
      phone,
      price,
      displayName,
      brand,
      displaySize,
      ean,
    });
  };

  shareProduct = () => {
    const { displaySize, description, displayName } = this.props.data;
    const title = `${displayName} ${displaySize} ספורטישופ - צמיגים לאופנועים`;
    const text = description;

    if (navigator.share) {
      navigator.share({
        title,
        text,
        url: window.location.href,
      });
    }
  };

  render() {
    const {
      classes,
      data,
      stats,
      nextShipment,
      nextShipmentDeadline,
      currentShipment,
      bestOfferEANs,
      getCreationTiresPredictedYearsForPreorder
    } = this.props;

    const {
      _id,
      displayName,
      brand,
      displaySize,
      ean,
      cdn = false,
      set = false,
      free_shipping: freeShipping,
      likes,
      description,
      youtube,
    } = data;

    const title = `${displayName} ${displaySize} ספורטישופ - צמיגים לאופנועים`;

    const liked =
      stats.data.likes.products.findIndex((p) => p.productId === _id) !== -1
        ? classes.liked
        : "";

    const { url: primaryPhoto } = commons.getProductPrimaryImage(cdn, ean);

    const bestOfferCTA = {
      content: hebrewLables.bestOfferCTA,
      disabled: false,
    };

    if (
      this.state.phone.length < 10 ||
      this.state.price === "" ||
      this.state.phone.substr(0, 2) !== "05"
    ) {
      bestOfferCTA.disabled = true;
    }

    if (this.state.transit) {
      bestOfferCTA.disabled = true;
      bestOfferCTA.content = (
        <CircularProgress size={20} className={classes.bestOfferSpinner} />
      );
    }

    return (
      <Card className={classes.card}>
        <CardHeader
          className={classes.cardHeader}
          title={displayName}
          subheader={brand}
        />
        <Divider />
        <div className={classes.cardMediaContainer}>
          <div className={classes.brandLogoContainer}>
            <img
              className={classes.brandLogo}
              style={{
                height: brand.toLowerCase() === "bridgestone" ? 15 : 17,
              }}
              src={brandLogoUrls[brand.toLowerCase()]}
            />
          </div>
          <Chip label={displaySize} className={classes.chip} />
          <CardMedia
            className={classes.media}
            image={primaryPhoto}
            title={`${brand} ${displayName} ${displaySize}`}
          />
        </div>
        <Divider />
        <CardContent className={classes.content}>
          <ResultItemPriceRow data={data} />
          <Button
            onClick={this.props.addToCart}
            variant="contained"
            color="primary"
            className={classes.button}
          >
            {hebrewLables.addToCartButtonText}
          </Button>

          {/* <Button
            variant="contained"
            color="primary"
            onClick={this.toggleBestOffer}
            className={classNames(classes.button, classes.ctaButton)}
          >
            {hebrewLables.bestOffer}
          </Button> */}

          {bestOfferEANs.includes(ean) && (
            <React.Fragment>
              <CardActions>
                <CheckIcon className={classes.greenCheck} />
                <Typography className={classes.boText} component="p">
                  {hebrewLables.bestOfferSubmitted}
                </Typography>
              </CardActions>
            </React.Fragment>
          )}
          {!bestOfferEANs.includes(ean) && this.state.bestOfferExpanded && (
            <React.Fragment>
              <CardActions>
                <Typography className={classes.boText} component="p">
                  {hebrewLables.bestOfferAlt}
                </Typography>
              </CardActions>
              <CardActions className={classes.ctaContainer}>
                <FormControl
                  component="fieldset"
                  className={classes.formControl}
                >
                  <TextField
                    id={`bo_amount_${ean}`}
                    disabled={this.state.transit}
                    inputProps={inputProps}
                    onChange={this.handleChangePrice}
                    label={hebrewLables.bestOfferAmount}
                    className={classes.textField}
                    value={this.state.price}
                    margin="normal"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">₪</InputAdornment>
                      ),
                    }}
                  />
                </FormControl>
                <FormControl
                  component="fieldset"
                  className={classes.formControl}
                >
                  <TextField
                    inputProps={inputProps}
                    disabled={this.state.transit}
                    id={`bo_phone_${ean}`}
                    onChange={this.handleChangePhone}
                    label={hebrewLables.bestOfferPhone}
                    className={classes.textField}
                    value={this.state.phone}
                    margin="normal"
                  />
                </FormControl>
              </CardActions>
              <CardActions className={classes.ctaContainer}>
                <Button
                  disabled={bestOfferCTA.disabled}
                  variant="contained"
                  color="primary"
                  onClick={this.submitBestOffer}
                  className={classNames(classes.button, classes.ctaButton)}
                >
                  {bestOfferCTA.content}
                </Button>
              </CardActions>
            </React.Fragment>
          )}

          {brand.toLowerCase() === "mitas" && (
            <div className={classes.chipsContainer}>
              <Chip
                label="MITAS SALE"
                className={classes.chipBlackFriday}
              />
            </div>
          )}

          <div className={classes.chipsContainer}>
            {set && (
              <Chip
                className={classNames(classes.featureChip)}
                avatar={
                  <Avatar>
                    <DoubleCheckIcon />
                  </Avatar>
                }
                label={hebrewLables.setOfTires}
              />
            )}

            {freeShipping && (
              <Chip
                className={classNames(classes.featureChip, classes.freeShip)}
                avatar={
                  <Avatar className={classes.freeShipIcon}>
                    <ShippingIcon />
                  </Avatar>
                }
                label={hebrewLables.freeShipping}
              />
            )}

            {!freeShipping && (
              <Chip
                className={classes.paidShippingChip}
                avatar={
                  <Avatar>
                    <ShippingIcon className={classes.paidShippingIcon} />
                  </Avatar>
                }
                label={hebrewLables.paidShipping}
              />
            )}
          </div>

          <ProductDataSheet
            data={data}
            nextShipment={nextShipment}
            currentShipment={currentShipment}
            nextShipmentDeadline={nextShipmentDeadline}
            getCreationTiresPredictedYearsForPreorder={getCreationTiresPredictedYearsForPreorder}
          />
        </CardContent>
        <Divider />
        <CardContent className={classes.content}>
          <Typography className={classes.description} paragraph>
            {description}
          </Typography>
          <div className={classes.collapseCardContent}>
            <iframe
              title={title}
              className={classes.youtube}
              src={`https://www.youtube.com/embed/${youtube}?rel=0`}
              frameBorder="0"
              allow="autoplay; encrypted-media"
              allowFullScreen
            />
          </div>
        </CardContent>
        <Divider />
        <CardActions className={classes.actions}>
          <div className={classes.likesCounter}>{likes}</div>
          <IconButton
            onClick={this.reportLike}
            aria-label={hebrewLables.likeButtonTextToggleOff}
          >
            <FavoriteIcon className={liked} />
          </IconButton>

          <Button
            variant="contained"
            onClick={this.props.addToCart}
            color="primary"
            className={classes.button}
          >
            {hebrewLables.addToCartButtonText}
          </Button>
        </CardActions>
      </Card>
    );
  }
}

ProductCard.propTypes = {
  classes: PropTypes.object.isRequired,
  submitBestOffer: PropTypes.func.isRequired,
  data: PropTypes.object.isRequired,
  bestOfferEANs: PropTypes.array.isRequired,
  stats: PropTypes.object,
  nextShipment: PropTypes.string,
  reportLike: PropTypes.func,
  addToCart: PropTypes.func,
  getCreationTiresPredictedYearsForPreorder: PropTypes.object
};

export default withStyles(styles)(ProductCard);
