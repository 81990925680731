import utils from './../../universal/utils';
const basePath = utils.getEdgeBasePath();

const cdnPath = 'https://s3.eu-west-2.amazonaws.com/sportyshop/';

const commons = {
    getProductPrimaryImage(cdn,ean) {
        let bCdn = false;
        if (cdn !== false && cdn[0].bucketPath.indexOf('.jpeg') !== -1) {
            bCdn = true;
        }

        const url = (bCdn === false) ? `${basePath}/api/v1/media/product/${ean}`
            : `${cdnPath}${cdn[0].bucketPath}`;

        return { url };
    },

    //Try same on shops image
    // getResizedShopImage(shopData){ //Pass all the shop data
    //     let resized = false;
    //     if(shopData.photo.indexOf(`${shopData.id}.jpeg`) !== -1){
    //         resized = true;
    //     }

    //     const url = (resized === false) ? `${basePath}/api/v1/media/shop/${shopData.id}` 
    //         : `${cdnPath}${shopData.photo}`;

    //     return { url };
    // }
};

export default commons;