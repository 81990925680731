import React from "react";
import PropTypes from "prop-types";

import { withStyles } from "@material-ui/core/styles";
import { Redirect } from "react-router-dom";
import { Helmet } from "react-helmet-async";

import Paper from "@material-ui/core/Paper";
import CircularProgress from "@material-ui/core/CircularProgress";
import Grid from "@material-ui/core/Grid";

import MotoList from "./../list/MotoList";
import FilterBar from "./../list/FilterBar";

const styles = (theme) => ({
  root: {
    padding: 16,
    marginTop: 0,
    marginLeft: "auto",
    marginRight: "auto",
  },
  paper: {
    margin: "80px auto",
    width: "90%",
    display: "flex",
    justifyContent: "center",
  },
  motoList: {
    marginTop: 53,
  },
  progress: {
    margin: theme.spacing(2),
  },
  pageRoot: {
    display: "flex",
    justifyContent: "center",
  },
  "@media (min-width: 1024px)": {
    filterBoxMobile: { display: "none" },
    motoList: {
      marginTop: -60,
    },
    paper: {
      margin: "150px auto",
      width: 1000,
    },
    pageRoot: {
      width: 1000,
      margin: "130px auto",
    },
  },
});

function shallowEqual(objA, objB) {
  if (objA === objB) {
    return true;
  }

  if (
    typeof objA !== "object" ||
    objA === null ||
    typeof objB !== "object" ||
    objB === null
  ) {
    return false;
  }

  var keysA = Object.keys(objA);
  var keysB = Object.keys(objB);

  if (keysA.length !== keysB.length) {
    return false;
  }

  // Test for A's keys different from B.
  var bHasOwnProperty = hasOwnProperty.bind(objB);
  for (var i = 0; i < keysA.length; i++) {
    if (!bHasOwnProperty(keysA[i]) || objA[keysA[i]] !== objB[keysA[i]]) {
      return false;
    }
  }

  return true;
}

function shallowCompare(instance, nextProps, nextState) {
  return (
    !shallowEqual(instance.props, nextProps) ||
    !shallowEqual(instance.state, nextState)
  );
}

class TireResultsPage extends React.Component {
  state = {
    redirectToCheckout: false,
  };

  componentDidMount() {
    const { width, profile, rim } = this.props.match.params;
    const size = `${width}_${profile}_${rim}`;
    this.setState({ width, profile, rim, size });

    this.props.actions.setListPending(true);
    this.props.actions.searchTiresByDimensions(width, profile, rim);

    window.scrollTo(0, 0);
  }

  shouldComponentUpdate(nextProps, nextState) {
    return shallowCompare(this, nextProps, nextState);
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    const currentSize = `${prevState.width}_${prevState.profile}_${prevState.rim}`;

    const nP = nextProps.match.params;
    const nextSize = `${nP.width}_${nP.profile}_${nP.rim}`;

    if (currentSize !== nextSize) {
      const { width, profile, rim } = nP;
      return { width, profile, rim, size: nextSize };
    } else return null;
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.size !== this.state.size) {
      //Perform some operation here
      this.props.actions.setListPending(true);
      this.props.actions.searchTiresByDimensions(width, profile, rim);
      window.scrollTo(0, 0);
    }
  }

  expressAddToCart = (ean, isSet) => {
    // Metrics
    this.props.actions.collectMetric({
      kind: "PRODUCT_ADD_TO_CART",
      source: "list_page",
      ean,
    });

    this.props.actions.addProductToCart(ean, isSet);
    this.setState({
      redirectToCheckout: true,
    });
  };

  updateFilters = (filters) => {
    this.props.actions.updateFilters(filters);
  };

  reportLike = (productId) => {
    this.props.actions.reportLikeEventForProduct(productId);
  };

  submitBestOffer = (offer) => {
    this.props.actions.submitBestOffer(offer);
  };

  render() {
    const { classes, catalog } = this.props;
    const { redirectToCheckout } = this.state;
    const { listPending, filters, bestOfferEANs, getCreationTiresPredictedYearsForPreorder } = catalog;

    const width = this.props.match.params.width;
    const profile = this.props.match.params.profile;
    const rim = this.props.match.params.rim;

    const seoTireSize = width + "/" + profile + "-" + rim;

    const metaTitle = "צמיגים לאופנועים במידה " + seoTireSize + " - ספורטישופ";
    const metaDesc =
      "מגוון רחב של צמיגים במידה " +
      seoTireSize +
      " תוכלו למצוא אצלנו במגוון מותגים מובילים - עד 70% הנחה - פירלי, מישלין, מצלר, קונטיננטל, ברידגסטון ועוד";

    return (
      <React.Fragment>
        <Helmet>
          <meta property="og:title" content={metaTitle} />
          {<meta property="og:description" content={metaDesc} />}
          {<meta property="description" content={metaDesc} />}
          <title>{metaTitle}</title>
        </Helmet>

        {listPending && (
          <Paper className={classes.paper}>
            <CircularProgress className={classes.progress} size={30} />
          </Paper>
        )}

        {!listPending && (
          <div className={classes.motoList}>
            <Grid container spacing={0} className={classes.pageRoot}>
              <Grid
                item
                xs={12}
                md={3}
                lg={3}
                className={classes.desktopSidebar}
              >
                <FilterBar
                  data={filters}
                  tires={catalog.motoTires}
                  updateFilters={this.updateFilters}
                />
              </Grid>

              <Grid item xs={12} md={9} lg={9}>
                <MotoList
                  className={classes.motoListContainer}
                  currentShipment={catalog.currentShipment}
                  nextShipment={catalog.nextShipment}
                  getCreationTiresPredictedYearsForPreorder={getCreationTiresPredictedYearsForPreorder}
                  tires={catalog.motoTires}
                  stats={catalog.stats}
                  filters={catalog.filters}
                  bestOfferEANs={bestOfferEANs}
                  submitBestOffer={this.submitBestOffer}
                  addToCart={this.expressAddToCart}
                  reportLike={this.reportLike}
                />
              </Grid>
            </Grid>
          </div>
        )}

        {redirectToCheckout && <Redirect to="/cart" />}
      </React.Fragment>
    );
  }
}

TireResultsPage.propTypes = {
  classes: PropTypes.object.isRequired,
  actions: PropTypes.object,
  catalog: PropTypes.object.isRequired,
  match: PropTypes.object,
};

export default withStyles(styles)(TireResultsPage);
