import React from 'react';
import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/core/styles';

import Card from '@material-ui/core/Card';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import CardHeader from '@material-ui/core/CardHeader';
import IconButton from '@material-ui/core/IconButton';

import Divider from '@material-ui/core/Divider';
import Button from '@material-ui/core/Button';
import Chip from '@material-ui/core/Chip';
import Avatar from '@material-ui/core/Avatar';

import FavoriteIcon from '@material-ui/icons/Favorite';
import PhoneIcon from '@material-ui/icons/Phone';
import NavigationIcon from '@material-ui/icons/Navigation';

import ShopCardPriceRow from './ShopCardPriceRow';
import SimpleAnchor from './../common/SimpleAnchor';

const hebrewLables = {
  likeButtonTextToggleOff: 'לחץ לסמן שאתה אוהב את המוצר',
  navigateToCTA: 'נווט למוסך',
  alignType: 'איזון סטטי',
  learnMoreButtonText: 'מידע נוסף',
  dialCTA: 'חייג',
  detailsCTA: 'פרטים',
};

const styles = theme => ({
  card: {
    marginTop: 15,
    maxWidth: 370,
    marginLeft: 'auto',
    marginRight: 'auto',
    position: 'relative',
  },
  youtube: {
    maxWidth: 400,
    width: '100%',
    height: 245,
  },
  content: {
    padding: 5,
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap',
  },
  media: {
    height: 194,
    backgroundPosition: 'top',
  },
  brandLogo: {
    height: 25,
    position: 'absolute',
    top: 5,
    right: 5,
  },
  actions: {
    display: 'flex',
  },
  likesCounter: Object.assign({}, theme.typography.button, {
    margin: theme.spacing(),
  }),
  youtubeContainer: {
    display: 'flex',
    justifyContent: 'center',
  },
  table: {
    maxWidth: 300,
  },
  expand: {
    transform: 'rotate(0deg)',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
    marginLeft: 'auto',
  },
  button: {
    margin: theme.spacing(),
  },
  description: {
    padding: theme.spacing(2),
  },
  cardMediaContainer: {
    position: 'relative',
  },
  liked: {
    color: '#ef7600',
  },
  shopPhone: {
    flip: false,
    position: 'absolute',
    top: 8,
    left: 10,
  },
  leftIcon: {
    marginLeft: theme.spacing(),
  },
  rightIcon: {
    marginRight: theme.spacing(),
  },
});

class ShopProfileCard extends React.Component {
  state = { expanded: false };

  handleExpandClick = () => {
    this.setState({ expanded: !this.state.expanded });
  };

  reportLike = () => {
    this.props.reportLike(this.props.data._id);
  };

  render() {
    const { classes, data, stats } = this.props;

    const { _id, displayName, phone, address, photo, likes } = data;

    const liked =
      stats.data.likes.shops.findIndex(p => p.shopId === _id) !== -1
        ? classes.liked
        : '';

    return (
      <Card className={classes.card}>
        <CardHeader
          className={classes.cardHeader}
          title={displayName}
          subheader={address}
        />
        <Chip
          avatar={
            <Avatar>
              <PhoneIcon />
            </Avatar>
          }
          className={classes.shopPhone}
          label={phone}
        />
        <Divider />
        <div className={classes.cardMediaContainer}>
          <CardMedia
            className={classes.media}
            image={photo}
            title={displayName}
          />
        </div>
        <Divider />
        <CardContent className={classes.content}>
          <ShopCardPriceRow data={data} />
        </CardContent>
        <Divider />
        <CardActions className={classes.actions} disableActionSpacing>
          <div className={classes.likesCounter}>{likes}</div>
          <IconButton aria-label={hebrewLables.likeButtonTextToggleOff}>
            <FavoriteIcon onClick={this.reportLike} className={liked} />
          </IconButton>

          <Button
            variant="contained"
            component={SimpleAnchor}
            to={`https://waze.com/ul?q=${encodeURI(address)}`}
            className={classes.button}
          >
            <NavigationIcon className={classes.rightIcon} />
            {hebrewLables.navigateToCTA}
          </Button>

          <Button
            component={SimpleAnchor}
            to={`tel:${phone}`}
            variant="contained"
            color="primary"
            className={classes.button}
          >
            {hebrewLables.dialCTA}
            <PhoneIcon className={classes.leftIcon} />
          </Button>
        </CardActions>
      </Card>
    );
  }
}

ShopProfileCard.propTypes = {
  classes: PropTypes.object.isRequired,
  data: PropTypes.object.isRequired,
  stats: PropTypes.object,
  reportLike: PropTypes.func.isRequired,
};

export default withStyles(styles)(ShopProfileCard);
