import React from "react";
import PropTypes from "prop-types";

import { withStyles } from "@material-ui/core/styles";
import CircularProgress from "@material-ui/core/CircularProgress";
import { Helmet } from "react-helmet-async";

import ProductCard from "./ProductCard";
import ProductCardDesktop from "./ProductCardDesktop";
import commons from "./../common/commons";
import FBPixelTrigger from "./FBPixelTrigger";
import { Typography, Paper } from "@material-ui/core";

const styles = (theme) => ({
  progress: {
    margin: theme.spacing(2),
  },
  rootNotFound: {
    margin: "80px auto",
    padding: 16,
    fontSize: 14,
    maxWidth: 600,
  },
});

const hebrewLabels = {
  addToCartSuccessText: "המוצר נוסף לעגלה",
  title: {
    motoTire: "צמיג לאופנוע",
    set: "סט צמיגים לאופנוע",
    sportyshop: "ספורטישופ",
  },
};

class ProductPage extends React.Component {
  componentDidMount() {
    // Load up the product given or redirect to not found page.
    const { ean } = this.props.match.params;

    this.props.actions.getProductByEAN(ean);

    // Metrics
    this.props.actions.collectMetric({
      kind: "PRODUCT_IMPRESSION",
      ean,
    });
  }

  submitBestOffer = (offer) => {
    this.props.actions.submitBestOffer(offer);
  };

  reportLike = (productId) => {
    this.props.actions.reportLikeEventForProduct(productId);
  };

  addToCart = () => {
    /* eslint no-undef: 0 */
    const { price, ean, set = false } = this.props.catalog.product;

    if (typeof fbq === "function") {
      fbq("track", "AddToCart", {
        content_ids: [ean],
        content_type: "product",
        value: price,
        currency: "ILS",
      });
    }

    this.props.actions.addProductToCart(ean, set);
    // Metrics
    this.props.actions.collectMetric({
      kind: "PRODUCT_ADD_TO_CART",
      source: "product_page",
      ean,
    });

    this.props.actions.toggleSnackbar(true, {
      message: hebrewLabels.addToCartSuccessText,
      type: "addToCart",
    });
  };

  render() {
    const { classes, catalog } = this.props;
    const {
      nextShipment,
      nextShipmentDeadline,
      currentShipment,
      bestOfferEANs,
      getCreationTiresPredictedYearsForPreorder
    } = catalog;

    let content;
    if (
      catalog.productPending ||
      (catalog.product === null && !catalog.productNotFound)
    ) {
      content = (
        <>
          <Paper className={classes.rootNotFound}>
            <CircularProgress className={classes.progress} size={50} />
          </Paper>
        </>
      );
    } else if (catalog.productNotFound) {
      return (
        <>
          <Paper className={classes.rootNotFound}>
            <Typography>אנו מצטערים אך המוצר שחיפשת אינו קיים באתר</Typography>
          </Paper>
        </>
      );
    } else {
      let metaTitle = `${catalog.product.brand} ${catalog.product.displayName} ${catalog.product.displaySize} - `;
      if ("set" in catalog.product && catalog.product.set) {
        metaTitle += hebrewLabels.title.set;
      } else {
        metaTitle += hebrewLabels.title.motoTire;
      }

      metaTitle += ` - ${hebrewLabels.title.sportyshop}`;

      const { ean, cdn } = catalog.product;

      // Find the primary photo
      const primaryPhoto = commons.getProductPrimaryImage(cdn, ean);

      const meta = catalog.product.meta || {};

      content = (
        <React.Fragment>
          <Helmet>
            <meta property="og:title" content={metaTitle} />
            <meta property="og:image" content={primaryPhoto.url} />
            <meta property="og:site_name" content="ספורטישופ יבוא צמיגים" />
            {"desc" in meta && (
              <meta property="og:description" content={meta.desc} />
            )}
            {"desc" in meta && (
              <meta property="description" content={meta.desc} />
            )}
            <title>{metaTitle}</title>
          </Helmet>

          <ProductCard
            submitBestOffer={this.submitBestOffer}
            nextShipment={nextShipment}
            nextShipmentDeadline={nextShipmentDeadline}
            currentShipment={currentShipment}
            bestOfferEANs={bestOfferEANs}
            data={catalog.product}
            stats={catalog.stats}
            addToCart={this.addToCart}
            reportLike={this.reportLike}
            getCreationTiresPredictedYearsForPreorder={getCreationTiresPredictedYearsForPreorder}
          />

          <ProductCardDesktop
            submitBestOffer={this.submitBestOffer}
            data={catalog.product}
            stats={catalog.stats}
            bestOfferEANs={bestOfferEANs}
            nextShipment={nextShipment}
            nextShipmentDeadline={nextShipmentDeadline}
            currentShipment={currentShipment}
            addToCart={this.addToCart}
            reportLike={this.reportLike}
            getCreationTiresPredictedYearsForPreorder={getCreationTiresPredictedYearsForPreorder}
          />

          <FBPixelTrigger data={catalog.product} />
        </React.Fragment>
      );
    }

    return <div>{content}</div>;
  }
}

ProductPage.propTypes = {
  classes: PropTypes.object.isRequired,
  actions: PropTypes.object,
  catalog: PropTypes.object.isRequired,
  match: PropTypes.object,
};

export default withStyles(styles)(ProductPage);
