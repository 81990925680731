import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

import { withStyles, makeStyles } from "@material-ui/core/styles";
import classNames from "classnames";

import Card from "@material-ui/core/Card";
import CardMedia from "@material-ui/core/CardMedia";
import CardContent from "@material-ui/core/CardContent";
import CardActions from "@material-ui/core/CardActions";
import CardHeader from "@material-ui/core/CardHeader";
import Collapse from "@material-ui/core/Collapse";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import Button from "@material-ui/core/Button";
import Chip from "@material-ui/core/Chip";
import Avatar from "@material-ui/core/Avatar";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import FormControl from "@material-ui/core/FormControl";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import CircularProgress from "@material-ui/core/CircularProgress";
import Tooltip from "@material-ui/core/Tooltip";

import FavoriteIcon from "@material-ui/icons/Favorite";
import ShareIcon from "@material-ui/icons/Share";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import CheckIcon from "@material-ui/icons/Check";
import DoubleCheckIcon from "@material-ui/icons/DoneAll";
import ShippingIcon from "@material-ui/icons/LocalShipping";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";

import ResultItemPriceRow from "./../common/ResultItemPriceRow";
import brandLogoUrls from "./../common/logoUrls";

import commons from "./../common/commons";
import { isSet } from "lodash";

const hebrewLables = {
  freeShipping: "משלוח חינם",
  lastPurchase: "רכישה אחרונה: {x}",
  setOfTires: "סט צמיגים",
  bestOfferSubmitted: "הצעת המחיר שלך נשלחה ותענה בהקדם!",
  likeButtonTextToggleOff: "לחץ לסמן שאתה אוהב את המוצר",
  bestOfferAlt:
    "הציעו מחיר שיותר מתאים לכם, אנחנו נבדוק אותו ונחזור אליכם עם תשובה. הצעות שיתקבלו תקפות ל-24 שעות בלבד",
  additionalDefault: "ספורט",
  outStock: "לא במלאי",
  inStock: "במלאי מיידי",
  preSale: "צפי הגעה: %s",
  ondemandText: "הזמן היום, קבל ב-%s",
  bestOfferCTA: "שלח הצעה",
  bestOffer: "הצע מחיר אחר",
  backorderStatus: "הזמנה מראש",
  backorderSmallText: "במידה ויוזמן - צפי הגעה למחסנים בארץ",
  bestOfferAmount: "כמה תרצו לשלם",
  bestOfferPhone: "מספר נייד",
  paidShipping: "משלוח: ₪60",
  learnMoreButtonText: "פרטים נוספים",
  addToCartButtonText: "הזמן עכשיו",
  showMoreArrowText: "פתח לעוד מידע",
  textForToolTip:
    "הצמיג שאתה רואה הוא הזמנה מוקדמת. הצמיג יוזמן במשלוח הבא ואינו נמצא במלאי כרגע. מועד ההספקה המשוער הוא רק הערכה ויכול להתארך בעקבות בעיות לוגיסטיות וגורמים נוספים.",
};

const styles = (theme) => ({
  card: {
    maxWidth: 400,
    marginTop: "18px",
    marginLeft: "auto",
    marginRight: "auto",
    position: "relative",
  },
  youtube: {
    maxWidth: 400,
    width: "100%",
    height: 245,
  },
  content: {
    padding: 5,
    display: "flex",
    justifyContent: "center",
    flexWrap: "wrap",
  },
  media: {
    height: 194,
    backgroundPosition: "top",
  },
  brandLogo: {
    position: "absolute",
    maxHeight: 26,
    right: 3,
    top: 3,
  },
  actions: {
    display: "flex",
  },
  likesCounter: Object.assign({}, theme.typography.button, {
    margin: theme.spacing(),
  }),
  youtubeContainer: {
    display: "flex",
    justifyContent: "center",
  },
  expand: {
    transform: "rotate(0deg)",
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.shortest,
    }),
    marginLeft: "auto",
  },
  button: {
    margin: theme.spacing(),
    width: 165,
  },
  cardHeader: {
    flip: false,
    direction: "ltr",
  },
  cardMediaContainer: {
    position: "relative",
  },
  outStock: {
    background: "#ba2218",
    color: "#fefefe",
    direction: "ltr",
  },
  greenBadgeInStock: {
    marginRight: 0,
    background: "#40916c",
    color: "#fefefe",
    direction: "ltr",
  },
  preSale: {
    marginRight: 0,
    background: "#0d9b4b",
    color: "#fefefe",
    direction: "ltr",
  },
  chipBlackFridayDesktop: {
    flip: false,
    direction: "ltr",
    fontWeight: 800,
    color: "#fafafa",
    position: "relative",
    background: "#063970",
    bottom: 237,
    left: 21,
  },
  chipBlackFriday: {
    flip: false,
    direction: "ltr",
    fontWeight: 800,
    color: "#fafafa",
    position: "absolute",
    background: "#063970",
    bottom: 243,
    right: 7,
  },
  chip: {
    flip: false,
    direction: "ltr",
    position: "absolute",
    top: "5px",
    right: "5px",
  },
  greenCheck: {
    color: "#0c9b4b",
  },
  trendIcon: {},
  trendingChip: {
    margin: theme.spacing(),
  },
  freeShippingChip: {
    marginLeft: theme.spacing(),
    background: "#e5a639",
  },
  liked: {
    color: "#ef7600",
  },
  chipsContainer: {
    display: "flex",
    margin: theme.spacing(),
    marginBottom: 0,
    justifyContent: "center",
    flexWrap: "wrap",
  },
  discountChip: {
    flip: false,
    direction: "ltr",
    position: "absolute",
    background: "#c10101",
    fontWeight: "700",
    color: "#fefefe",
    top: 35,
    left: 5,
  },
  desktopBox: { display: "none" },
  expandOpen: {
    transform: "rotate(180deg)",
  },
  paidShippingChip: {
    background: "#e5a639",
    // marginTop: 8,
  },
  bestOfferSpinner: {
    color: "#848383",
  },
  boText: {
    fontSize: 14,
  },
  paidShippingAvatar: {
    background: "#e5a639",
    color: "#313330",
  },
  paidShippingIcon: {
    background: "#f4b342",
    color: "#313330",
  },
  textField: {
    margin: 0,
  },
  ctaButton: {
    width: 165,
  },
  ctaContainer: {
    justifyContent: "center",
  },
  dotChip: {
    color: "#fefefe",
    direction: "rtl",
    background: "#0d949b",
    marginLeft: 0,
    marginTop: 8,
  },
  chipBackOrder: {
    height: "auto",
    whiteSpace: "normal",
    wordBreak: "break-word",
    borderTopRightRadius: "0px",
    borderBottomRightRadius: "0px",
    borderRight: "4px solid #023e8a",
    padding: "4px 0 4px 0",
  },
  containerInfoIcon: {
    display: "flex",
    alignItems: "center",
  },
  infoIcon: {
    color: "#023e8a",
    cursor: "pointer",
    marginLeft: "5px",
  },
  backOrderTitle: {
    fontSize: "14px",
    fontWeight: "bold",
  },
  backOrderText: {
    fontSize: "12px",
  },
  "@media (min-width: 1024px)": {
    card: {
      display: "none",
    },
    dotChip: {
      marginTop: 4,
      marginRight: 4,
    },
    button: {
      width: 130,
    },
    ctaButton: {
      width: 130,
    },
    paidShippingChip: {
      background: "#e5a639",
      marginTop: 4,
    },
    desktopBox: {
      width: "100%",
      margin: "16px 8px 30px 8px",
      position: "relative",
      padding: 8,
      background: "#fefefe",
      left: 13,
      // height: 75,
      display: "block",
    },
    brandLogo: {
      position: "absolute",
      top: "initial",
      maxHeight: 26,
      bottom: 3,
      left: 3,
    },
    brandContainer: {
      position: "relative",
      height: 25,
    },
    desktopDataContainer: {
      position: "relative",
    },
    desktopControlContainer: {
      display: "inline-block",
      position: "absolute",
      width: "auto",
      bottom: -5,
      right: -5,
    },
    desktopThumbContainer: {
      justifyContent: "center",
      position: "relative",
      top: 19,
    },
    desktopDataInnerContainer: {
      // direction: 'ltr',
      marginBottom: 60,
      display: "block",
      paddingLeft: 16,
    },
    primaryPhotoContainer: {},
    title: {
      fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
      fontSize: 24,
      margin: 8,
    },
    tireSize: {
      fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
      fontSize: 17,
      direction: "rtl",
      margin: "4px 8px",
    },
    bestOfferDesktopCont: {
      margin: "8px 0 0 0",
    },
    formControl: {
      margin: 8,
    },
    subheading: {
      margin: "4px 8px",
      fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
      fontSize: 15,
      fontWeight: 400,
      color: "#9a9a9a",
    },
    featuresPool: {
      direction: "ltr",
      margin: "4px 8px",
    },
    featureItem: {
      margin: 4,
    },
    bestOfferSuccessTextCont: {
      paddingTop: 3,
    },
    chipsContainer: {
      margin: 0,
    },
    bestOfferCheckCont: {
      textAlign: "right",
      padding: "0 0 0 8px",
    },
    desktopPrimaryPhoto: {
      height: 194,
    },
    chipBackOrder: {
      height: "auto",
      whiteSpace: "normal",
      wordBreak: "break-word",
      borderTopRightRadius: "0px",
      borderBottomRightRadius: "0px",
      borderRight: "4px solid #023e8a",
      padding: "4px 0 4px 0",
    },
    containerInfoIcon: {
      display: "flex",
      alignItems: "center",
    },
    infoIcon: {
      color: "#023e8a",
      cursor: "pointer",
      marginLeft: "5px",
    },
    backOrderTitle: {
      fontSize: "14px",
      fontWeight: "bold",
    },
    backOrderText: {
      fontSize: "12px",
    },
  },
});

const inputProps = {
  pattern: "[0-9]*",
  type: "number",
  step: "0.01",
};

const useStylesBootstrap = makeStyles((theme) => ({
  arrow: {
    color: theme.palette.common.black,
  },
  tooltip: {
    backgroundColor: theme.palette.common.black,
    fontSize: 14,
  },
}));

function BootstrapTooltip(props) {
  const classes = useStylesBootstrap();

  return <Tooltip arrow classes={classes} {...props} />;
}

class MotoTireResultItem extends React.Component {
  state = {
    expanded: false,
    bestOfferExpanded: false,
    phone: "",
    price: "",
    transit: false,
  };

  toggleBestOffer = () => {
    this.setState({ bestOfferExpanded: !this.state.bestOfferExpanded });
  };

  handleChangePrice = () => {
    this.setState({ ...this.state, price: event.target.value });
  };

  handleChangePhone = () => {
    this.setState({ ...this.state, phone: event.target.value });
  };

  handleExpandClick = () => {
    this.setState({ expanded: !this.state.expanded });
  };

  AddToCartExpress = () => {
    const { ean, set = false, price } = this.props.data;

    if (typeof fbq === "function") {
      fbq("track", "AddToCart", {
        content_ids: [ean],
        content_type: "product",
        value: price,
        currency: "ILS",
      });
    }

    this.props.addToCart(ean, set);
  };

  submitBestOffer = () => {
    const { phone, price } = this.state;
    const { displayName, brand, displaySize, ean } = this.props.data;

    if (typeof fbq === "function") {
      fbq("track", "AddToCart", {
        content_ids: [ean],
        content_type: "product",
        value: price,
        currency: "ILS",
      });
    }

    this.setState({ transit: true });

    this.props.submitBestOffer({
      phone,
      price,
      displayName,
      brand,
      displaySize,
      ean,
    });
  };

  reportLike = () => {
    this.props.reportLike(this.props.data._id);
  };

  render() {
    const {
      classes,
      data,
      stats,
      nextShipment,
      currentShipment,
      getCreationTiresPredictedYearsForPreorder,
      bestOfferEANs,
    } = this.props;

    const {
      _id,
      displayName,
      brand,
      displaySize,
      cdn = false,
      set = false,
      ean,
      name,
      dot = "2021",
      free_shipping,
      visibility,
      tireType = hebrewLables.additionalDefault,
      likes,
      description,
      youtube,
    } = data;

    const liked =
      stats.data.likes.products.findIndex((p) => p.productId === _id) !== -1
        ? classes.liked
        : "";

    const { url: primaryPhoto } = commons.getProductPrimaryImage(cdn, ean);

    let stockLabel;
    let greenBadgeStockClassname = classes.greenBadgeInStock;
    const displayGreenVisibilityChip =
      visibility === "instock" || visibility === "preorder" ? true : false;
    const displayOnDemandChip = visibility === "ondemand";
    const displayOutOfStock = visibility === "outofstock";

    switch (visibility) {
      case "instock":
        stockLabel = hebrewLables.inStock;
        greenBadgeStockClassname = classes.preSale;
        break;
      case "preorder":
        stockLabel = hebrewLables.preSale.replace("%s", currentShipment);
        break;
    }

    if (displayOnDemandChip) {
      // stockLabel = hebrewLables.ondemandText.replace("%s", nextShipment);
      stockLabel = nextShipment;
    }

    const bestOfferCTA = {
      content: hebrewLables.bestOfferCTA,
      disabled: false,
    };

    if (
      this.state.phone.length < 10 ||
      this.state.price === "" ||
      this.state.phone.substr(0, 2) !== "05"
    ) {
      bestOfferCTA.disabled = true;
    }

    if (this.state.transit) {
      bestOfferCTA.disabled = true;
      bestOfferCTA.content = (
        <CircularProgress size={20} className={classes.bestOfferSpinner} />
      );
    }

    let dotDisplayTemplate = "יצור: %s";
    let dotDisplay;

    if (set) {
      dotDisplay = dotDisplayTemplate.replace(
        "%s",
        `${dot.rear} / ${dot.front}`
      );
    } else {
      dotDisplay = dotDisplayTemplate.replace("%s", dot);
    }

    // If the product is a preorder product -- override what's written in the db
    if (displayOnDemandChip) {
      dotDisplay = dotDisplayTemplate.replace(
        "%s",
        `${getCreationTiresPredictedYearsForPreorder.previous}/${getCreationTiresPredictedYearsForPreorder.current}`
      );
    }

    return (
      <React.Fragment>
        <Card className={classes.card}>
          <CardHeader
            className={classes.cardHeader}
            title={displayName}
            subheader={brand}
          />
          <Divider />
          <div className={classes.cardMediaContainer}>
            <div className={classes.brandLogoContainer}>
              <img
                alt={brand}
                className={classes.brandLogo}
                src={brandLogoUrls[brand.toLowerCase()]}
              />
            </div>

            <Chip label={displaySize} className={classes.chip} />
            {brand.toLowerCase() === "mitas" && (
              <Chip label="MITAS SALE" className={classes.chipBlackFriday} />
            )}

            <CardMedia
              className={classes.media}
              image={primaryPhoto}
              title={`${brand} ${displayName} ${displaySize}`}
            />
          </div>
          <Divider />
          <CardContent className={classes.content}>
            <ResultItemPriceRow data={data} />

            {displayOutOfStock && (
              <div className={classes.chipsContainer}>
                <Chip
                  className={classes.outStock}
                  label={hebrewLables.outStock}
                />
              </div>
            )}

            {displayGreenVisibilityChip && (
              <div className={classes.chipsContainer}>
                <Chip className={greenBadgeStockClassname} label={stockLabel} />
              </div>
            )}

            <Chip className={classes.dotChip} label={dotDisplay} />

            {/*ELIA*/}
            {displayOnDemandChip && (
              <div className={classes.chipsContainer}>
                <Chip
                  className={classNames(
                    classes.featureItem,
                    classes.chipBackOrder
                  )}
                  label={
                    <span>
                      <span className={classes.containerInfoIcon}>
                        <span className={classes.backOrderTitle}>
                          {hebrewLables.backorderStatus}
                        </span>
                        <Tooltip title={hebrewLables.textForToolTip} arrow>
                          <InfoOutlinedIcon className={classes.infoIcon} />
                        </Tooltip>
                      </span>
                      <span className={classes.backOrderText}>
                        {hebrewLables.backorderSmallText}
                      </span>
                      <br />
                      <span
                        className={classes.backOrderText}
                        style={{ fontWeight: "bold" }}
                      >
                        {stockLabel}
                      </span>
                    </span>
                  }
                />
              </div>
            )}

            <div className={classes.chipsContainer}>
              {set && (
                <Chip
                  avatar={
                    <Avatar>
                      <DoubleCheckIcon />
                    </Avatar>
                  }
                  label={hebrewLables.setOfTires}
                />
              )}

              {free_shipping && (
                <Chip
                  className={classes.freeShippingChip}
                  avatar={
                    <Avatar className={classes.paidShippingAvatar}>
                      <ShippingIcon />
                    </Avatar>
                  }
                  label={hebrewLables.freeShipping}
                />
              )}

              {!free_shipping && (
                <Chip
                  className={classes.paidShippingChip}
                  avatar={
                    <Avatar>
                      <ShippingIcon className={classes.paidShippingIcon} />
                    </Avatar>
                  }
                  label={hebrewLables.paidShipping}
                />
              )}
            </div>
          </CardContent>
          <Divider />
          <CardActions className={classes.actions}>
            <div className={classes.likesCounter}>{likes}</div>
            <IconButton
              onClick={this.reportLike}
              aria-label={hebrewLables.likeButtonTextToggleOff}
            >
              <FavoriteIcon className={liked} />
            </IconButton>
            <Button
              component={Link}
              variant="contained"
              className={classes.button}
              to={`/tire/moto/${ean}/${name}`}
            >
              {hebrewLables.learnMoreButtonText}
            </Button>
            <IconButton
              variant="contained"
              className={classNames(classes.expand, {
                [classes.expandOpen]: this.state.expanded,
              })}
              onClick={this.handleExpandClick}
              aria-expanded={this.state.expanded}
              aria-label={hebrewLables.showMoreArrowText}
            >
              <ExpandMoreIcon />
            </IconButton>
          </CardActions>
          <CardActions className={classes.ctaContainer}>
            <Button
              onClick={this.AddToCartExpress}
              variant="contained"
              color="primary"
              className={classNames(classes.button, classes.ctaButton)}
            >
              {hebrewLables.addToCartButtonText}
            </Button>

            {/* <Button
              variant="contained"
              color="primary"
              onClick={this.toggleBestOffer}
              className={classNames(classes.button, classes.ctaButton)}
            >
              {hebrewLables.bestOffer}
            </Button> */}
          </CardActions>
          {bestOfferEANs.includes(ean) && (
            <React.Fragment>
              <CardActions>
                <CheckIcon className={classes.greenCheck} />
                <Typography className={classes.boText} component="p">
                  {hebrewLables.bestOfferSubmitted}
                </Typography>
              </CardActions>
            </React.Fragment>
          )}
          {!bestOfferEANs.includes(ean) && this.state.bestOfferExpanded && (
            <React.Fragment>
              <CardActions>
                <Typography className={classes.boText} component="p">
                  {hebrewLables.bestOfferAlt}
                </Typography>
              </CardActions>
              <CardActions className={classes.ctaContainer}>
                <FormControl
                  component="fieldset"
                  className={classes.formControl}
                >
                  <TextField
                    id={`bo_amount_${ean}`}
                    disabled={this.state.transit}
                    inputProps={inputProps}
                    onChange={this.handleChangePrice}
                    label={hebrewLables.bestOfferAmount}
                    className={classes.textField}
                    value={this.state.price}
                    margin="normal"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">₪</InputAdornment>
                      ),
                    }}
                  />
                </FormControl>
                <FormControl
                  component="fieldset"
                  className={classes.formControl}
                >
                  <TextField
                    inputProps={inputProps}
                    disabled={this.state.transit}
                    id={`bo_phone_${ean}`}
                    onChange={this.handleChangePhone}
                    label={hebrewLables.bestOfferPhone}
                    className={classes.textField}
                    value={this.state.phone}
                    margin="normal"
                  />
                </FormControl>
              </CardActions>
              <CardActions className={classes.ctaContainer}>
                <Button
                  disabled={bestOfferCTA.disabled}
                  variant="contained"
                  color="primary"
                  onClick={this.submitBestOffer}
                  className={classNames(classes.button, classes.ctaButton)}
                >
                  {bestOfferCTA.content}
                </Button>
              </CardActions>
            </React.Fragment>
          )}
          <Collapse in={this.state.expanded} timeout="auto" unmountOnExit>
            <CardContent>
              <Typography paragraph variant="p">
                {description}
              </Typography>
              <div className={classes.collapseCardContent}>
                <iframe
                  title="YouTube clip of the specified product"
                  className={classes.youtube}
                  src={`https://www.youtube.com/embed/${youtube}?rel=0`}
                  frameBorder="0"
                  allow="autoplay; encrypted-media"
                  allowFullscreen
                />
              </div>
            </CardContent>
          </Collapse>
        </Card>
        <Paper className={classes.desktopBox}>
          <Grid container spacing={0} className={classes.desktopDataContainer}>
            <Grid item xs={3} className={classes.primaryPhotoContainer}>
              <Grid
                container
                spacing={0}
                className={classes.desktopThumbContainer}
              >
                <Grid item>
                  <a href={`/tire/moto/${ean}/${name}`}>
                    <img
                      className={classes.desktopPrimaryPhoto}
                      alt={`${brand} ${displayName} ${displaySize}`}
                      src={primaryPhoto}
                    />
                  </a>

                  {brand.toLowerCase() === "mitas" && (
                    <Chip
                      label="MITAS SALE"
                      className={classes.chipBlackFridayDesktop}
                    />
                  )}
                </Grid>
              </Grid>
              <Grid container spacing={0} className={classes.brandContainer} />
            </Grid>
            <Grid item xs={9}>
              <Grid
                container
                spacing={0}
                className={classes.desktopDataInnerContainer}
              >
                <h3 className={classes.title}>{displayName}</h3>

                <h4 className={classes.subheading}>{brand}</h4>

                <Chip className={classes.tireSize} label={displaySize} />

                <Grid container spacing={0} className={classes.featuresPool}>
                  {displayOutOfStock && (
                    <div className={classes.chipsContainer}>
                      <Chip
                        className={classNames(
                          classes.featureItem,
                          classes.outStock
                        )}
                        label={hebrewLables.outStock}
                      />
                    </div>
                  )}

                  {displayGreenVisibilityChip && (
                    <div className={classes.chipsContainer}>
                      <Chip
                        className={classNames(
                          classes.featureItem,
                          greenBadgeStockClassname
                        )}
                        label={stockLabel}
                      />
                    </div>
                  )}

                  {displayOnDemandChip && (
                    <div className={classes.chipsContainer}>
                      <Chip
                        className={classNames(
                          classes.featureItem,
                          classes.chipBackOrder
                        )}
                        label={
                          <span>
                            <span className={classes.containerInfoIcon}>
                              <span className={classes.backOrderTitle}>
                                {hebrewLables.backorderStatus}
                              </span>
                              <BootstrapTooltip
                                title={hebrewLables.textForToolTip}
                              >
                                <InfoOutlinedIcon
                                  className={classes.infoIcon}
                                />
                              </BootstrapTooltip>
                            </span>
                            <span className={classes.backOrderText}>
                              {hebrewLables.backorderSmallText}
                            </span>
                            <br />
                            <span
                              className={classes.backOrderText}
                              style={{ fontWeight: "bold" }}
                            >
                              {stockLabel}
                            </span>
                          </span>
                        }
                      />
                    </div>
                  )}

                  <Chip className={classes.dotChip} label={dotDisplay} />

                  {set && (
                    <Chip
                      className={classNames(classes.featureItem)}
                      avatar={
                        <Avatar>
                          <DoubleCheckIcon />
                        </Avatar>
                      }
                      label={hebrewLables.setOfTires}
                    />
                  )}

                  {free_shipping && (
                    <Chip
                      className={classNames(
                        classes.featureItem,
                        classes.freeShippingChip
                      )}
                      avatar={
                        <Avatar>
                          <ShippingIcon
                            className={classes.paidShippingAvatar}
                          />
                        </Avatar>
                      }
                      label={hebrewLables.freeShipping}
                    />
                  )}

                  {!free_shipping && (
                    <Chip
                      className={classes.paidShippingChip}
                      avatar={
                        <Avatar>
                          <ShippingIcon className={classes.paidShippingIcon} />
                        </Avatar>
                      }
                      label={hebrewLables.paidShipping}
                    />
                  )}

                  <Chip className={classes.featureItem} label={tireType} />
                </Grid>
                <ResultItemPriceRow data={data} />
              </Grid>
              <Grid
                container
                spacing={0}
                className={classes.desktopControlContainer}
              >
                <span className={classes.likesCounter}>{likes}</span>
                <IconButton
                  onClick={this.reportLike}
                  aria-label={hebrewLables.likeButtonTextToggleOff}
                >
                  <FavoriteIcon className={liked} />
                </IconButton>

                <Button
                  component={Link}
                  variant="contained"
                  className={classes.button}
                  to={`/tire/moto/${ean}/${name}`}
                >
                  {hebrewLables.learnMoreButtonText}
                </Button>

                <Button
                  onClick={this.AddToCartExpress}
                  variant="contained"
                  color="primary"
                  className={classes.button}
                >
                  {hebrewLables.addToCartButtonText}
                </Button>

                {/* <Button
                  variant="contained"
                  color="primary"
                  onClick={this.toggleBestOffer}
                  className={classNames(classes.button, classes.ctaButton)}
                >
                  {hebrewLables.bestOffer}
                </Button> */}
              </Grid>
            </Grid>
          </Grid>

          {(this.state.bestOfferExpanded || bestOfferEANs.includes(ean)) && (
            <Grid
              container
              spacing={0}
              className={classes.bestOfferDesktopCont}
            >
              <Grid item xs={3}></Grid>
              <Grid item xs={9}>
                {bestOfferEANs.includes(ean) && (
                  <React.Fragment>
                    <Grid container spacing={0}>
                      <Grid item xs={3} className={classes.bestOfferCheckCont}>
                        <CheckIcon className={classes.greenCheck} />
                      </Grid>
                      <Grid
                        item
                        xs={9}
                        spacing={8}
                        className={classes.bestOfferSuccessTextCont}
                      >
                        <Typography className={classes.boText} component="p">
                          {hebrewLables.bestOfferSubmitted}
                        </Typography>
                      </Grid>
                    </Grid>
                  </React.Fragment>
                )}
                {!bestOfferEANs.includes(ean) &&
                  this.state.bestOfferExpanded && (
                    <React.Fragment>
                      <div>
                        <Typography className={classes.boText} component="p">
                          {hebrewLables.bestOfferAlt}
                        </Typography>
                      </div>
                      <div className={classes.ctaContainer}>
                        <FormControl
                          component="fieldset"
                          className={classes.formControl}
                        >
                          <TextField
                            id={`bo_amount_${ean}`}
                            disabled={this.state.transit}
                            inputProps={inputProps}
                            onChange={this.handleChangePrice}
                            label={hebrewLables.bestOfferAmount}
                            className={classes.textField}
                            value={this.state.price}
                            margin="normal"
                            InputProps={{
                              startAdornment: (
                                <InputAdornment position="start">
                                  ₪
                                </InputAdornment>
                              ),
                            }}
                          />
                        </FormControl>
                        <FormControl
                          component="fieldset"
                          className={classes.formControl}
                        >
                          <TextField
                            inputProps={inputProps}
                            disabled={this.state.transit}
                            id={`bo_phone_${ean}`}
                            onChange={this.handleChangePhone}
                            label={hebrewLables.bestOfferPhone}
                            className={classes.textField}
                            value={this.state.phone}
                            margin="normal"
                          />
                        </FormControl>
                      </div>
                      <div className={classes.ctaContainer}>
                        <Button
                          disabled={bestOfferCTA.disabled}
                          variant="contained"
                          color="primary"
                          onClick={this.submitBestOffer}
                          className={classNames(
                            classes.button,
                            classes.ctaButton
                          )}
                        >
                          {bestOfferCTA.content}
                        </Button>
                      </div>
                    </React.Fragment>
                  )}
              </Grid>
            </Grid>
          )}
          <img
            alt={brand}
            className={classes.brandLogo}
            src={brandLogoUrls[brand.toLowerCase()]}
          />
        </Paper>
      </React.Fragment>
    );
  }
}

MotoTireResultItem.propTypes = {
  classes: PropTypes.object.isRequired,
  stats: PropTypes.object,
  data: PropTypes.object.isRequired,
  bestOfferEANs: PropTypes.array.isRequired,
  nextShipment: PropTypes.string,
  currentShipment: PropTypes.string,
  addToCart: PropTypes.func.isRequired,
  reportLike: PropTypes.func.isRequired,
  submitBestOffer: PropTypes.func.isRequired,
  getCreationTiresPredictedYearsForPreorder: PropTypes.object,
};

export default withStyles(styles)(MotoTireResultItem);
