import React from "react";
import PropTypes from "prop-types";
import { orderBy } from "lodash";

import { withStyles } from "@material-ui/core/styles";

import MotoTireResultItem from "./MotoTireResultItem";
import { FilterSharp } from "@material-ui/icons";

const styles = () => ({});

class MotoList extends React.Component {
  renderProducts(
    products,
    stats,
    nextShipment,
    currentShipment,
    bestOfferEANs,
  ) {
    // Apply default sorting logic here

    // Sort by lowest price first
    let sortedProducts = orderBy(products, ["price"], ["asc"]);

    return sortedProducts.map((item) => (
      <MotoTireResultItem
        addToCart={this.props.addToCart}
        reportLike={this.props.reportLike}
        submitBestOffer={this.props.submitBestOffer}
        nextShipment={nextShipment}
        currentShipment={currentShipment}
        getCreationTiresPredictedYearsForPreorder={this.props.getCreationTiresPredictedYearsForPreorder}
        bestOfferEANs={bestOfferEANs}
        data={item}
        stats={stats}
        key={item.ean}
      />
    ));
  }

  filterByBrand({ tires, brands }) {
    if (brands.length === 0) {
      return tires;
    }

    return tires.filter(
      (t) =>
        brands.findIndex((tt) => tt.toLowerCase() === t.brand.toLowerCase()) !==
        -1
    );
  }

  render() {
    const {
      tires,
      stats,
      filters,
      nextShipment,
      bestOfferEANs,
      currentShipment,
    } = this.props;

    // First we want to display sets and then normal single tires.
    const sets = tires.filter((p) => {
      if ("set" in p && p.set) {
        return true;
      }
      return false;
    });

    const singleTires = tires.filter((p) => {
      if (!p.set) {
        return true;
      }
      return false;
    });

    let inStockSingleTires = singleTires.filter(
      (p) => p.visibility === "instock"
    );
    let inStockSets = sets.filter((p) => p.visibility === "instock");

    let preOrderSingleTires = singleTires.filter(
      (p) => p.visibility === "preorder"
    );
    let preOrderSets = sets.filter((p) => p.visibility === "preorder");

    let onDemandSingleTires = singleTires.filter(
      (p) => p.visibility === "ondemand"
    );
    let onDemandSets = sets.filter((p) => p.visibility === "ondemand");

    let outOfStockSingleTires = singleTires.filter(
      (p) => p.visibility === "outofstock"
    );
    let outOfStockSets = sets.filter((p) => p.visibility === "outofstock");

    let allOtherSingleTires = singleTires.filter((p) => !"visibility" in p);
    let allOtherSets = sets.filter((p) => !"visibility" in p);

    return (
      <React.Fragment>
        {!filters.hideSets &&
          this.renderProducts(
            this.filterByBrand({
              tires: inStockSets,
              brands: filters.brands || [],
            }),
            stats,
            nextShipment,
            currentShipment,
            bestOfferEANs
          )}
        {this.renderProducts(
          this.filterByBrand({
            tires: inStockSingleTires,
            brands: filters.brands || [],
          }),
          stats,
          nextShipment,
          currentShipment,
          bestOfferEANs
        )}

        {!filters.hideSets &&
          this.renderProducts(
            this.filterByBrand({
              tires: preOrderSets,
              brands: filters.brands || [],
            }),
            stats,
            nextShipment,
            currentShipment,
            bestOfferEANs
          )}
        {this.renderProducts(
          this.filterByBrand({
            tires: preOrderSingleTires,
            brands: filters.brands || [],
          }),
          stats,
          nextShipment,
          currentShipment,
          bestOfferEANs
        )}

        {!filters.hideSets &&
          this.renderProducts(
            this.filterByBrand({
              tires: onDemandSets,
              brands: filters.brands || [],
            }),
            stats,
            nextShipment,
            currentShipment,
            bestOfferEANs
          )}
        {this.renderProducts(
          this.filterByBrand({
            tires: onDemandSingleTires,
            brands: filters.brands || [],
          }),
          stats,
          nextShipment,
          currentShipment,
          bestOfferEANs
        )}

        {!filters.hideSets &&
          this.renderProducts(
            this.filterByBrand({
              tires: outOfStockSets,
              brands: filters.brands || [],
            }),
            stats,
            nextShipment,
            currentShipment,
            bestOfferEANs
          )}
        {this.renderProducts(
          this.filterByBrand({
            tires: outOfStockSingleTires,
            brands: filters.brands || [],
          }),
          stats,
          nextShipment,
          currentShipment,
          bestOfferEANs
        )}

        {!filters.hideSets &&
          this.renderProducts(
            this.filterByBrand({
              tires: allOtherSets,
              brands: filters.brands || [],
            }),
            stats,
            nextShipment,
            currentShipment,
            bestOfferEANs
          )}
        {this.renderProducts(
          this.filterByBrand({
            tires: allOtherSingleTires,
            brands: filters.brands || [],
          }),
          stats,
          nextShipment,
          currentShipment,
          bestOfferEANs
        )}
      </React.Fragment>
    );
  }
}

MotoList.propTypes = {
  tires: PropTypes.array,
  stats: PropTypes.object,
  filters: PropTypes.object,
  nextShipment: PropTypes.string,
  currentShipment: PropTypes.string,
  bestOfferEANs: PropTypes.array.isRequired,
  addToCart: PropTypes.func.isRequired,
  reportLike: PropTypes.func.isRequired,
  submitBestOffer: PropTypes.func.isRequired,
  getCreationTiresPredictedYearsForPreorder: PropTypes.object
};

export default withStyles(styles)(MotoList);
