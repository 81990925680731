import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

import { withStyles } from "@material-ui/core/styles";
import classNames from "classnames";

import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import AddToCartIcon from "@material-ui/icons/AddShoppingCart";
import Button from "@material-ui/core/Button";
import Chip from "@material-ui/core/Chip";
import Avatar from "@material-ui/core/Avatar";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";

import FormControl from "@material-ui/core/FormControl";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import CircularProgress from "@material-ui/core/CircularProgress";

import FavoriteIcon from "@material-ui/icons/Favorite";
import ShareIcon from "@material-ui/icons/Share";
import DoubleCheckIcon from "@material-ui/icons/DoneAll";
import CheckIcon from "@material-ui/icons/Check";
import ShippingIcon from "@material-ui/icons/LocalShipping";

import { vsprintf } from "sprintf-js";

import brandLogoUrls from "./../common/logoUrls";

import commons from "./../common/commons";
import ProductDataSheet from "./ProductSheet";

const hebrewLables = {
  freeShipping: "משלוח חינם",
  lastPurchase: "רכישה אחרונה: {x}",
  setOfTires: "סט צמיגים",
  backToResults: "חזרה לתוצאות החיפוש",
  defaultSpeed: "מידע לא זמין",
  paidShipping: "משלוח: ₪60",
  bestOfferAlt:
    "הציעו מחיר שיותר מתאים לכם, אנחנו נבדוק אותו ונחזור אליכם עם תשובה. הצעות שיתקבלו תקפות ל-24 שעות בלבד",
  bestOfferSubmitted: "הצעת המחיר שלך נשלחה ותענה בהקדם!",
  bestOffer: "הצע מחיר אחר",
  bestOfferCTA: "שלח הצעה",
  bestOfferAmount: "כמה תרצו לשלם",
  bestOfferPhone: "מספר נייד",
  disclaimer:
    "* הצמיג מסופק ללא חישוק גם במידה ומופיע בתמונה לצורך מטרות תצוגה",
  likeButtonTextToggleOff: "לחץ לסמן שאתה אוהב את המוצר",
  learnMoreButtonText: "מידע נוסף",
  addToCartButtonText: "הוסף לסל",
};

const styles = (theme) => ({
  youtube: {
    width: "100%",
    height: 245,
  },
  likesCounter: Object.assign({}, theme.typography.button, {
    margin: theme.spacing(),
    lineHeight: "27pt",
  }),
  button: {
    width: "100%",
    marginTop: 10,
  },
  description: {
    padding: theme.spacing(2),
  },
  chip: {
    flip: false,
    fontSize: 16,
    direction: "ltr",
  },
  liked: {
    color: "#ef7600",
  },
  root: {
    display: "none",
  },
  chipBlackFriday: {
    flip: false,
    direction: "ltr",
    fontWeight: 800,
    width: 138,
    margin: "4px auto",
    color: "#fafafa",
    // position: "absolute",
    background: "#063970",
    // top: "35px",
    // left: "7px",
  },
  "@media (min-width: 1024px)": {
    breadcrumbsCont: {
      marginTop: 70,
    },
    bestOfferSpinner: {
      color: "#848383",
    },
    boText: {
      fontSize: 12,
    },
    ctaButton: {
      margin: "8px 0",
    },
    greenCheck: {
      color: "#0c9b4b",
    },
    paper: {
      padding: 8,
      display: "flex",
      margin: 8,
      flex: 1,
      flexWrap: "wrap",
      flexDirection: "column",
      justifyContent: "center",
    },
    primaryPhoto: {
      height: 280,
    },
    paidShippingChip: {
      width: 140,
      background: "#e5a639",
      marginTop: 4,
      marginLeft: "auto",
      marginRight: "auto",
    },
    paidShippingAvatar: {
      background: "#e5a639",
      color: "#313330",
    },
    paidShippingIcon: {
      background: "#f4b342",
      color: "#313330",
    },
    photoDisclaimer: {
      width: "100%",
      marginTop: 8,
      color: "#7c7c7c",
      fontSize: 12,
    },
    brand: {
      color: "#7c7c7c",
      fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
      fontSize: 17,
      margin: 8,
    },
    boSuccessCont: {
      display: "flex",
      flexDirection: "row",
    },
    boSuccessText: {
      padding: "2px 4px",
    },
    title: {
      fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
      fontSize: 24,
      margin: 8,
    },
    brandLogoCont: {
      margin: "8px 0",
    },
    primaryPhotoCont: {
      display: "flex",
      justifyContent: "center",
    },
    price: {
      fontSize: "22pt",
    },
    priceDisclaimer: {
      width: "100%",
      fontFamily: "Lato",
      textAlign: "center",
      marginTop: 8,
      color: "#7c7c7c",
      fontSize: 12,
    },
    root: {
      width: 1000,
      marginLeft: "auto",
      marginRight: "auto",
      display: "block",
    },
    freeShip: {
      color: "#313330",
      background: "#f4b342",
    },
    freeShipIcon: {
      color: "#313330",
      background: "#e5a73b",
    },
    primaryItem: {
      display: "flex",
      flex: 1,
    },
    freeShippingCont: {
      margin: "8px 0",
      display: "flex",
      justifyContent: "center",
    },
  },
});

const inputProps = {
  pattern: "[0-9]*",
  type: "number",
  step: "0.01",
};

class ProductCardDesktop extends React.Component {
  state = {
    expanded: false,
    bestOfferExpanded: false,
    phone: "",
    price: "",
    transit: false,
  };

  handleExpandClick = () => {
    this.setState({ expanded: !this.state.expanded });
  };

  reportLike = () => {
    this.props.reportLike(this.props.data._id);
  };

  shareProduct = () => {
    const { displaySize, description, displayName } = this.props.data;
    const title = `${displayName} ${displaySize} ספורטישופ - צמיגים לאופנועים`;
    const text = description;

    if (navigator.share) {
      navigator.share({
        title,
        text,
        url: location.href,
      });
    }
  };

  toggleBestOffer = () => {
    this.setState({ bestOfferExpanded: !this.state.bestOfferExpanded });
  };

  handleChangePrice = () => {
    this.setState({ ...this.state, price: event.target.value });
  };

  handleChangePhone = () => {
    this.setState({ ...this.state, phone: event.target.value });
  };

  submitBestOffer = () => {
    const { phone, price } = this.state;
    const { displayName, brand, displaySize, ean } = this.props.data;

    if (typeof fbq === "function") {
      fbq("track", "AddToCart", {
        content_ids: [ean],
        content_type: "product",
        value: price,
        currency: "ILS",
      });
    }

    this.setState({ transit: true });

    this.props.submitBestOffer({
      phone,
      price,
      displayName,
      brand,
      displaySize,
      ean,
    });
  };

  render() {
    const {
      classes,
      data,
      stats,
      nextShipment,
      nextShipmentDeadline,
      currentShipment,
      bestOfferEANs,
      getCreationTiresPredictedYearsForPreorder
    } = this.props;

    const {
      _id,
      displayName,
      brand,
      displaySize,
      ean,
      cdn = false,
      set = false,
      width,
      profile,
      rim,
      price,
      free_shipping,
      likes,
      description,
      youtube,
    } = data;

    const liked =
      stats.data.likes.products.findIndex((p) => p.productId === _id) !== -1
        ? classes.liked
        : "";

    const { url: primaryPhoto } = commons.getProductPrimaryImage(cdn, ean);
    const altTitle = `${brand} ${displayName} ${displaySize}`;

    const backUrl = `/list/moto/${width}/${profile}/${rim}`;

    const bestOfferCTA = {
      content: hebrewLables.bestOfferCTA,
      disabled: false,
    };

    if (
      this.state.phone.length < 10 ||
      this.state.price === "" ||
      this.state.phone.substr(0, 2) !== "05"
    ) {
      bestOfferCTA.disabled = true;
    }

    if (this.state.transit) {
      bestOfferCTA.disabled = true;
      bestOfferCTA.content = (
        <CircularProgress size={20} className={classes.bestOfferSpinner} />
      );
    }

    return (
      <div className={classes.root}>
        <Grid container spacing={0} className={classes.breadcrumbsCont}>
          <Grid item lg={12} md={12}>
            <Paper className={classes.paper} style={{ display: "block" }}>
              <Button
                component={Link}
                to={backUrl}
                variant="text"
                color="default"
              >
                {hebrewLables.backToResults}
              </Button>
            </Paper>
          </Grid>
        </Grid>
        <Grid
          container
          spacing={0}
          className={classes.primaryCont}
          style={{ justifyContent: "space-between" }}
        >
          <Grid item className={classes.primaryItem} lg={4}>
            <Paper className={classNames(classes.paper)}>
              <Grid container className={classes.primaryPhotoCont}>
                <img
                  className={classes.primaryPhoto}
                  src={primaryPhoto}
                  title={altTitle}
                  alt={altTitle}
                />
              </Grid>

              <div className={classes.photoDisclaimer}>
                {hebrewLables.disclaimer}
              </div>

              <Grid
                container
                className={classNames(
                  classes.primaryPhotoCont,
                  classes.brandLogoCont
                )}
              >
                <img
                  title={brand}
                  alt={brand}
                  style={{
                    height: brand.toLowerCase() === "bridgestone" ? 15 : 25,
                  }}
                  src={brandLogoUrls[brand.toLowerCase()]}
                />
              </Grid>
            </Paper>
          </Grid>
          <Grid item className={classes.primaryItem} lg={5}>
            <Paper className={classNames(classes.paper)}>
              <h2 className={classes.title}>{displayName}</h2>
              <div className={classes.brand}>{brand}</div>

              <Chip label={displaySize} className={classes.chip} />

              <ProductDataSheet
                data={data}
                nextShipment={nextShipment}
                currentShipment={currentShipment}
                nextShipmentDeadline={nextShipmentDeadline}
                getCreationTiresPredictedYearsForPreorder={getCreationTiresPredictedYearsForPreorder}
              />
            </Paper>
          </Grid>
          <Grid item className={classes.primaryItem} lg={3}>
            <Paper className={classNames(classes.paper)}>
              <div className={classNames(classes.price, "result-item-pricing")}>
                {vsprintf("%.2f", [price])} ₪
              </div>
              <div className={classes.priceDisclaimer}>
                המחיר כולל מע״מ ומיסים
              </div>
              {free_shipping && (
                <div className={classes.freeShippingCont}>
                  <Chip
                    className={classes.freeShip}
                    avatar={
                      <Avatar className={classes.freeShipIcon}>
                        <ShippingIcon />
                      </Avatar>
                    }
                    label={hebrewLables.freeShipping}
                  />
                </div>
              )}

              {brand.toLowerCase() === "mitas" && (
                <Chip label="MITAS SALE" className={classes.chipBlackFriday} />
              )}

              {!free_shipping && (
                <Chip
                  className={classes.paidShippingChip}
                  avatar={
                    <Avatar>
                      <ShippingIcon className={classes.paidShippingIcon} />
                    </Avatar>
                  }
                  label={hebrewLables.paidShipping}
                />
              )}

              {set && (
                <div className={classes.freeShippingCont}>
                  <Chip
                    avatar={
                      <Avatar>
                        <DoubleCheckIcon />
                      </Avatar>
                    }
                    label={hebrewLables.setOfTires}
                  />
                </div>
              )}

              <Button
                onClick={this.props.addToCart}
                variant="contained"
                color="primary"
                className={classes.button}
              >
                {hebrewLables.addToCartButtonText}
              </Button>

              {/* <Button
                variant="contained"
                color="primary"
                onClick={this.toggleBestOffer}
                className={classNames(classes.button, classes.ctaButton)}>
                {hebrewLables.bestOffer}
              </Button> */}

              {bestOfferEANs.includes(ean) && (
                <React.Fragment>
                  <div className={classes.boSuccessCont}>
                    <CheckIcon className={classes.greenCheck} />
                    <Typography
                      className={classNames(
                        classes.boText,
                        classes.boSuccessText
                      )}
                      component="p"
                    >
                      {hebrewLables.bestOfferSubmitted}
                    </Typography>
                  </div>
                </React.Fragment>
              )}
              {!bestOfferEANs.includes(ean) && this.state.bestOfferExpanded && (
                <React.Fragment>
                  <div>
                    <Typography className={classes.boText} component="p">
                      {hebrewLables.bestOfferAlt}
                    </Typography>
                  </div>
                  <div className={classes.ctaContainer}>
                    <FormControl
                      component="fieldset"
                      className={classes.formControl}
                      style={{ width: 110 }}
                    >
                      <TextField
                        id={`bo_amount_${ean}`}
                        disabled={this.state.transit}
                        inputProps={inputProps}
                        onChange={this.handleChangePrice}
                        label={hebrewLables.bestOfferAmount}
                        className={classes.textField}
                        value={this.state.price}
                        margin="normal"
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">₪</InputAdornment>
                          ),
                        }}
                      />
                    </FormControl>
                    <FormControl
                      component="fieldset"
                      className={classes.formControl}
                    >
                      <TextField
                        inputProps={inputProps}
                        disabled={this.state.transit}
                        id={`bo_phone_${ean}`}
                        onChange={this.handleChangePhone}
                        label={hebrewLables.bestOfferPhone}
                        className={classes.textField}
                        value={this.state.phone}
                        margin="normal"
                      />
                    </FormControl>
                  </div>

                  <Button
                    disabled={bestOfferCTA.disabled}
                    variant="contained"
                    color="primary"
                    onClick={this.submitBestOffer}
                    className={classNames(classes.button, classes.ctaButton)}
                  >
                    {bestOfferCTA.content}
                  </Button>
                </React.Fragment>
              )}

              <div className={classes.freeShippingCont}>
                <div className={classes.likesCounter}>{likes}</div>
                <IconButton
                  onClick={this.reportLike}
                  aria-label={hebrewLables.likeButtonTextToggleOff}
                >
                  <FavoriteIcon className={liked} />
                </IconButton>
              </div>
            </Paper>
          </Grid>
        </Grid>

        <Grid container spacing={0}>
          <Grid item className={classes.primaryItem} lg={6}>
            <Paper className={classes.paper}>
              <Typography
                className={classes.description}
                paragraph
                variant="body2"
              >
                {description}
              </Typography>
            </Paper>
          </Grid>
          <Grid item className={classes.primaryItem} lg={6}>
            <Paper className={classes.paper}>
              <iframe
                className={classes.youtube}
                src={`https://www.youtube.com/embed/${youtube}?rel=0`}
                frameBorder="0"
                allow="autoplay; encrypted-media"
                allowFullScreen
              />
            </Paper>
          </Grid>
        </Grid>
      </div>
    );
  }
}

ProductCardDesktop.propTypes = {
  classes: PropTypes.object.isRequired,
  submitBestOffer: PropTypes.func.isRequired,
  bestOfferEANs: PropTypes.array.isRequired,
  data: PropTypes.object.isRequired,
  stats: PropTypes.object,
  nextShipment: PropTypes.string,
  reportLike: PropTypes.func,
  addToCart: PropTypes.func,
  getCreationTiresPredictedYearsForPreorder: PropTypes.object
};

export default withStyles(styles)(ProductCardDesktop);
