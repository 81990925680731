export const LOAD_TIRE_DIMS_WIDTHS = 'LOAD_TIRE_DIMS_WIDTHS';
export const MOTO_SELECT_WIDTH_DIM = 'MOTO_SELECT_WIDTH_DIM';
export const MOTO_SELECT_PROFILE_DIM = 'MOTO_SELECT_PROFILE_DIM';
export const MOTO_TIRE_DIMS_PROFILES = 'MOTO_TIRE_DIMS_PROFILES';
export const MOTO_TIRE_DIMS_RIMS = 'MOTO_TIRE_DIMS_RIMS';
export const MOTO_SELECT_RIM_DIM = 'MOTO_SELECT_RIM_DIM';
export const MOTO_RESET_DIMS = 'MOTO_RESET_DIMS';
export const LOAD_MOTO_TIRES = 'LOAD_MOTO_TIRES';
export const LOAD_MOTO_TIRES_BY_CAT = 'LOAD_MOTO_TIRES_BY_CAT';
export const LOAD_TIRES_YEARS = 'LOAD_TIRES_YEARS';

export const LOADING = 'LOADING';

export const BEST_OFFER_SENT = 'BEST_OFFER_SENT';
export const LOAD_PRODUCT = 'LOAD_PRODUCT';
export const ADD_PRODUCT_TO_CART = 'ADD_PRODUCT_TO_CART';
export const REMOVE_PRODUCT_FROM_CART = 'REMOVE_PRODUCT_FROM_CART';
export const CHANGE_PRODUCT_QUANTITY_IN_CART = 'CHANGE_PRODUCT_QUANTITY_IN_CART';
export const LOAD_INSTALL_CITIES = 'LOAD_INSTALL_CITIES';
export const LOAD_SHOPS = 'LOAD_SHOPS';
export const LOAD_OPENDATES_FOR_INSTALLATION = 'LOAD_OPENDATES_FOR_INSTALLATION';
export const LOAD_INSTALL_SLOTS = 'LOAD_INSTALL_SLOTS';
export const LOAD_PRODUCT_INFLIGHT = 'LOAD_PRODUCT_INFLIGHT';

export const LOAD_TOP_SELLERS = 'LOAD_TOP_SELLERS';
export const LOAD_POPULAR_SIZES = 'LOAD_POPULAR_SIZES';

export const RESTORE_CART = 'RESTORE_CART';
export const TOGGLE_SNACKBAR = 'TOGGLE_SNACKBAR';
export const CART_PRODUCTS_DATA = 'CART_PRODUCTS_DATA';
export const FORM_FIELD_CHANGE_PAYMENT = 'FORM_FIELD_CHANGE_PAYMENT';
export const FORM_FIELD_CHANGE_PERSONAL = 'FORM_FIELD_CHANGE_PERSONAL';
export const FORM_FIELD_CHANGE_SHIPPING = 'FORM_FIELD_CHANGE_SHIPPING';
// New for additional notes
export const CHANGE_ADDITIONAL_NOTES = 'CHANGE_ADDITIONAL_NOTES';

export const CHANGE_SIP = 'CHANGE_SIP';
export const CHANGE_INSTALL_CITY = 'CHANGE_INSTALL_CITY';
export const CHANGE_INSTALL_SHOP = 'CHANGE_INSTALL_SHOP';
export const CLEAR_PICK_SHOP = 'CLEAR_PICK_SHOP';
export const PICK_INSTALL_DATE = 'PICK_INSTALL_DATE';
export const PICK_INSTALL_SLOT = 'PICK_INSTALL_SLOT';
export const CHANGE_PAYMENT_METHOD = 'CHANGE_PAYMENT_METHOD';
export const COMPLETE_ORDER = 'COMPLETE_ORDER';

export const PRODUCT_LIKE = 'PRODUCT_LIKE';
export const SHOP_LIKE = 'SHOP_LIKE';
export const STATS_UPDATED = 'STATS_UPDATED';
export const UPDATE_FILTERS = 'UPDATE_FILTERS';

export const LOAD_CITIES_FOR_SHOPS_PAGE = 'LOAD_CITIES_FOR_SHOPS_PAGE';
export const PICK_CITY_SHOPS_PAGE = 'PICK_CITY_SHOPS_PAGE';
export const LOAD_SHOPS_INDEX_PAGE = 'LOAD_SHOPS_INDEX_PAGE';
export const LOAD_SHOP_BY_NAME = 'LOAD_SHOP_BY_NAME';

export const ADMIN_LOAD_ORDERS = 'ADMIN_LOAD_ORDERS';
export const ADMIN_STATUS_INFLIGHT = 'ADMIN_STATUS_INFLIGHT';
export const ADMIN_ORDER_UPDATED = 'ADMIN_ORDER_UPDATED';
export const ADMIN_LOAD_ORDER_PAYMENT = 'ADMIN_LOAD_ORDER_PAYMENT';
